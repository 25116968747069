import React from 'react';
import '~/assets/styles/main.scss';

const Thanks = () => {
  return (
    <div className="page">
      <div className="page-section -intro">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="large">Thank you!</h1>
              <p>We got your email and will contact you soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
